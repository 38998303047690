import React from "react"
import FAQ from "../components/faq"
import SEO from "../components/seo"
import Phone from "../images/phone.svg"

const PhoneCta = () => <><img style={{ width: 29, marginRight: 9 }} src={Phone} alt='phone icon' /></>
const name = typeof window !== `undefined` ? window.sessionStorage.getItem('name') : null
const ThanksPage = () => (
  <>
    <SEO title="Quiz Results: Thank you" />
    <div className="hero-section in-pages-bg">
      <div className="cover-overlay"></div>
      <div className="overlay-text">
<h3 className="is-white font-size-42 font-weight-bold">Thank you {name}!</h3>
        <p className="is-white font-size-20">We received your submission and will get back to you within 1 business day. If you have an urgent question, please call us at 
          <a className="phone-cta desktop font-weight-bold ml-2" href="tel:+18446873646"><PhoneCta />(844) 934-4863</a>
        </p>
      </div>
    </div>
    <h2 className="is-purple p-4 header-allignment">Frequently Asked Questions</h2>
    <FAQ />
  </>
)

export default ThanksPage
